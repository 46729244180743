.ProjectsAndInstitutions {
  height: 657px;
  padding: 48px;
}

.pni-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.projects-title,
.institutions-title {
  color: #474747;
  font-size: 24px;
}

.projects-imgs {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.projects-imgs > a > img {
  max-height: 160px;
}

.institutions-imgs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.institutions-imgs > a > img {
  max-height: 100px;
}

@media screen and (max-width: 900px) {
  .ProjectsAndInstitutions {
    padding: 24px;
  }

  .projects-title,
  .institutions-title {
    text-align: center;
    font-size: 24px;
  }

  .projects-imgs > a > img {
    height: 100px;
  }

  .institutions-imgs > a > img {
    height: 70px;
  }
}

@media screen and (max-width: 480px) {
  .projects-imgs > a > img {
    height: 80px;
  }

  .institutions-imgs > a > img {
    height: 55px;
  }
}
