.Slider {
  position: relative;
  width: 100%;
  height: 92.31%;
  display: flex;
}

.Slider.active {
  border-bottom: 1px solid antiquewhite;
}

.Slider.dark.active {
  border-bottom: 1px solid #474747aa;
}

.slide {
  min-width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
}

.goLeft,
.goRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
  background: none;
  border: none;
  cursor: pointer;
}

.goLeft > img,
.goRight > img {
  max-height: 30px;
}

.goLeft {
  left: 0;
}

.goRight {
  right: 0;
}

.slider-indicators {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  max-width: 235px;
  left: 50%;
  transform: translateX(-50%);
}

.slider-indicator {
  margin: 0px auto 23px auto;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgba(250, 235, 215, 0.5);
  transition: all 0.3s ease-in-out;
}

.slider-indicator.active {
  background-color: antiquewhite;
}
