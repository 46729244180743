.news-card {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px;
  width: 426px;
  max-height: 208.26px;
  /* backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
  display: flex;
  transition: ease all 0.3s;
  position: relative;
}

.news-card:hover {
  transform: scale(1.1);
  height: 468;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  background-color: rgba(255, 255, 255, 0.481);
}

.news-card:hover > .news-notch {
  display: block;
  opacity: 1;
  background-color: rgb(255, 255, 255);
}

.news-notch {
  opacity: 0;
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 25%;
  height: 10px;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  transition: ease all 0.3s;
  /* z-index: 1000; */
}

.news-card:hover > .info-caption > a {
  color: black;
  transition: ease all 1s;
}

.news-card-right {
  border-top-right-radius: 19px;
}

.thumbnail-container {
  width: 172px;
  height: 130px;
}

.thumbnail-container > img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.info-caption {
  padding: 0px 0px 0px 20px;
  width: 54.24% /*142.64px*/;
  height: 130px;
}

.skeleton-bars {
  max-width: 100%;
}

.info-caption > a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}

@media screen and (max-width: 1104px) {
  .info-caption > a {
    font-size: 0.9rem;
  }

  .thumbnail-container {
    width: 53.82%;
  }
}

@media screen and (max-width: 1024px) {
  .news-cards {
    display: none;
  }
}

/* Small news section */

.news-card-sm {
  height: 198px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.background-thumbnail-sm {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in-out;
}

.info-caption-sm {
  background-color: rgba(0, 0, 0, 0.6);
  text-align: left;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  padding: 24px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.info-caption-sm > a {
  color: #ffffff;
  font-size: 17.5px;
  font-weight: 100;
  text-decoration: none;
}

.info-caption-sm-mid {
  text-align: right;
}

.title-sm {
  color: #ffffff;
  font-weight: normal;
  font-size: 1.875rem;
  margin: 0 0 40px 0;
  line-height: 1.3;
}

.news-card-sm:hover > .background-thumbnail-sm {
  transform: scale(1.05);
}

.news-card-sm:hover > .info-caption-sm {
  background-color: rgba(0, 0, 0, 0.8);
}
