.btn-component {
  padding: 10px;
  background-color: #474747;
  border: none;
  color: white;
  font-size: 17.5px;
  font-weight: 300;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-width: 130px;
  display: flex;
  justify-content: baseline;
}

.btn-component:hover {
  background-color: var(--primary-color-bg);
}

.btn-component > img {
  height: 25px;
  border-radius: 50%;
  margin-right: 7px;
}
