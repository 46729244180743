.Footer {
  height: 320px;
  width: 100%;
  background-color: #474747;
  padding: 24px;
}

.footer-container {
  height: 100%;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footer-follow-us {
  display: flex;
  align-items: center;
}

.footer-follow-us > p {
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
}

.footer-icon {
  margin: 0px 10px 0px 10px;
}

.footer-icon > img {
  transform: scale(0.6);
}

.footer-caption-container {
  display: flex;
  flex-direction: column;
}

.caption-title {
  font-size: 22px;
  font-weight: 300;
  color: var(--primary-color-text);
}

.caption-text {
  font-size: 17.5px;
  font-weight: 300;
  color: white;
  text-align: center;
  margin-top: 25px;
}

@media screen and (min-width: 600px) {
  .Footer {
    height: 190px;
  }

  .footer-container {
    flex-direction: row;
  }

  .caption-text {
    margin-top: 15px;
  }
}

@media screen and (min-width: 768px) {
  .Footer {
    padding: 48px;
  }

  .footer-container {
    justify-content: space-between;
  }

  .footer-caption-container {
    flex-direction: row;
  }

  .caption-title {
    text-align: end;
  }

  .caption-text {
    margin: 0px 0px 0px 10px;
    text-align: right;
  }
}
