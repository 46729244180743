.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    display: flex;
    width: 70%;
    height: 70%;
    background-color: #fff;
    border-radius: 5px;
    flex-direction: row;
    gap: 20px;
}

.modal-description {
    max-width: 100%;
    flex-grow: 2;
    margin: 10px 10px 10px 0;
}

.modal-image {
    width: 50%;
    height: 100%;
    border-radius: 5px 0 0 5px;
    object-fit: cover;
}