.upcoming-events-header {
  height: 100px;
  background-color: rgb(230, 233, 238);
  padding: 24px;
  display: flex;
}

.upcoming-events-title {
  margin: auto 0;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
}

.upcoming-events-container {
  background-color: rgb(239, 239, 239);
  height: fit-content;
  padding: 96px 24px;
}

@media screen and (min-width: 480px) {
  .upcoming-events-title {
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {
  .upcoming-events-header {
    padding: 24px 48px;
  }

  .upcoming-events-container {
    padding: 96px 48px;
  }
}
