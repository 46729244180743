.Pill {
  font-size: 12px;
  font-weight: 400;
  background-color: #17a2b8;
  color: azure;
  padding: 3px;
  border-radius: 16px;
  width: fit-content;
  text-align: center;
}

#text {
  text-align: center;
}
