.Secretary {
  height: 80vh;
  background-image: url("../../../assets/Vesuvio.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
  overflow: hidden;
}

.secretary-container {
  height: fit-content;
  max-width: 1000px;
  align-self: center;
  background-color: rgba(28, 28, 28, 0.65);
  padding: 24px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.secretary-title {
  color: var(--primary-color-text);
  font-size: 40px;
  text-align: center;
}

.secretary-description-container {
  margin-top: 15px;
  display: flex;
}

.secretary-img {
  max-height: 90px;
  border-radius: 50%;
}

.secretary-description {
  color: antiquewhite;
  font-size: 17.5px;
  font-weight: 300;
  margin-left: 15px;
  align-self: center;
}

.secretary-img-location {
  border-radius: 25px 0% 0% 25px;
  padding: 15px;
  background-color: #1c1c1c;
  position: absolute;
  right: 0;
  bottom: 48px;
  display: flex;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  transform: translateX(80%);
}

/* .secretary-img-location:hover {
  transition: all 0.5s ease-in-out;
  transform: translateX(0%);
} */

.secretary-img-location.active {
  transition: all 0.5s ease-in-out;
  transform: translateX(0%);
}

.pin-icon {
  position: relative;
  height: 30px;
  width: 30px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.location {
  font-size: 17.5px;
  font-weight: 300;
  color: white;
  align-self: center;
  margin: 0px 10px;
}

.location-flag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

@media screen and (min-width: 420px) {
  .secretary-title {
    font-size: 32px;
    text-align: left;
  }
}

@media screen and (min-width: 760px) {
  .secretary-container {
    padding: 24px 48px;
    border-radius: 0px 5px 5px 0px;
  }

  .secretary-img {
    max-height: 100px;
  }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease-in-out;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
