.Navbar {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 100;
  position: fixed;
  top: 0;
  z-index: 10000 !important;
}

.navbar-logo {
  justify-self: start;
  margin: 0 0 0 48px;
  font-weight: 300;
  font-size: 1.25rem;
}

.navbar-logo > a {
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 300;
  color: #000000;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 90vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: #474747;
  font-size: 1rem;
  font-weight: 300;
}

.nav-links:hover {
  color: white;
  background-color: var(--primary-color-bg);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #000000;
}

.fa-times {
  color: #000000;
}

.menu-icon {
  height: 20px;
  width: 20px;
  padding: 5px;
  border-radius: 4px;
  display: none;
}

.line {
  width: 20px;
  height: 2px;
  border-radius: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: #474747;
  transition: all 0.3s ease-out;
}

.line-1.active {
  transform: rotateZ(-45deg) translate(-4px, 4px);
  background-color: #474747;
  transition: all 0.3s ease-out;
}

.line-2.active {
  background-color: #474747;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.line-3.active {
  transform: rotateZ(45deg) translate(-5px, -4px);
  background-color: #474747;
  transition: all 0.3s ease-out;
}

@media screen and (max-width: 960px) {
  body.disabledScroll {
    overflow: hidden;
  }

  .nav-menu-container {
    position: relative;
  }

  .nav-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    top: 53px;
    left: -100vw;
    transition: all 0.5s ease;
    z-index: 100;
    opacity: 1;
    margin: 0;
    padding: 0;
  }

  .nav-menu.active {
    background-color: #474747;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    color: rgb(255, 255, 255);
    padding: 2rem 0 2rem 0;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #616161;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 60%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 24px;
    transform: translate(-50%, 40%);
    cursor: pointer;
  }
}

@media screen and (max-width: 540px) {
  .navbar-logo {
    margin: 0 0 0 24px;
  }
}
