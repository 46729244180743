* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.2;
}

*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --primary-color-bg: rgb(223, 133, 67);
  --primary-color-text: rgb(223, 133, 67);
  --secondary-color: rgb(127, 148, 169);
  --tertiary-color: rgb(241, 241, 176);
  --quaternary-color: rgb(157, 37, 3);
  --light-color: rgba(255, 255, 255, 1);
  --dark-color: rgba(0, 0, 0, 1);
  --dark-color-text: #212529;
  --box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
}

h1 {
  margin: 0;
  font-size: 49px;
  font-weight: 300;
}

p {
  margin: 0;
}

.text-light {
  color: rgb(238, 238, 238);
}
