.WhatIsGeothermalEnergyExtended {
  height: 100vh;
  background-image: url("../../../../assets/Brown Soil Blurred.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 24px;
}

.wigee-header {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.wigee-header-title {
  margin-bottom: 140px;
  color: white;
  font-weight: 300;
}

.wigee-header-title > span {
  color: var(--primary-color-text);
  font-size: 48px;
}

.wigee-captions {
  font-size: 17.5px;
  font-weight: 300;
  text-align: center;
  width: 100%;
}

.wigee-caption-1,
.wigee-caption-2,
.wigee-caption-3,
.wigee-caption-4 {
  padding: 24px;
}

.wigee-caption-1,
.wigee-caption-2 {
  padding: 96px 24px;
  text-align: left;
}

.wigee-caption-1-container,
.wigee-caption-2-container,
.wigee-caption-3-container,
.wigee-caption-4-container {
  margin: auto;
  line-height: 25px;
}

.wigee-caption-3 {
  height: 60vh;
  display: flex;
}

.wigee-caption-3-target {
  height: 86px;
}

.wigee-caption-3-container {
  display: flex;
  flex-direction: column;
}

.wigee-caption-3-text {
  margin-top: 24px;
  line-height: 25px;
}

.wigee-caption-3-text > span {
  color: var(--primary-color-text);
}

.wigee-caption-4 {
  height: fit-content;
  background-color: #f4f4f7;
  display: flex;
}

.wigee-caption-4-container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wigee-caption-4-text {
  margin-top: 16px;
}

.wigee-caption-4-container > div {
  align-self: center;
  margin: 24px;
}

@media screen and (min-width: 960px) {
  .WhatIsGeothermalEnergyExtended {
    padding: 48px;
  }

  .wigee-caption-1,
  .wigee-caption-2 {
    padding: 48px 24px;
    text-align: left;
  }

  .wigee-caption-3 {
    height: 40vh;
  }

  .wigee-caption-3-container {
    flex-direction: row;
    text-align: left;
  }

  .wigee-caption-3-container > div {
    margin-left: 24px;
  }

  .wigee-caption-4 {
    height: 60vh;
  }

  .wigee-caption-1-container,
  .wigee-caption-2-container,
  .wigee-caption-3-container,
  .wigee-caption-4-container {
    width: 60vw;
  }

  .wigee-caption-4-container {
    width: 70vw;
    flex-direction: row;
  }
}

hr {
  border: none;
  height: 1px;
  background: rgb(129, 129, 129);
  background: linear-gradient(
    90deg,
    rgba(129, 129, 129, 0.3) 0%,
    rgba(114, 114, 114, 0.8) 25%,
    rgba(91, 91, 91, 0.9) 50%,
    rgba(111, 111, 111, 0.8015581232492998) 75%,
    rgba(129, 129, 129, 0.3) 100%
  );
  width: 50vw;
  margin: auto;
}
